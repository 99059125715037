import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styles from './InquiriesConfigurationFormServicesField.module.scss';
import {
	Box,
	FormControlLabel,
	IconButton,
	List,
	ListItem,
	ListItemText,
	Typography,
} from '@mui/material';
import Icon from '../../../../../elements/icons';
import { ServiceNameField, ServiceNameFieldProps } from './ServiceNameField';
import { useInquiriesFormField } from '../useInquiriesFormField';
import { Draggable, DraggableProvided } from '@hello-pangea/dnd';
import MuiModal from '../../../../../mui/MuiModal/MuiModal';
import MuiCheckbox from '../../../../../mui/MuiCheckbox';
import MuiTextFieldText from '../../../../../mui/MuiTextField/MuiTextFieldText/MuiTextFieldText';
import { ArrayElement, notEmpty } from '../../../../../lib/helpers/apollo';
import classNamesHelper from 'classnames';
import rpcShared from '@rockpapercoin/rpc-shared';
import { useInquiriesConfigurationFields } from '../../useInquiriesConfigurationFields';

export type InquiriesConfigurationFormServicesFieldProps = {
	index: number;
} & ArrayElement<
ReturnType<typeof useInquiriesConfigurationFields>['usedItems']
>;

const getTimestamp = ( index?: number ) =>
	`${ new Date().getTime().toString() }-${ typeof index === 'number' ? index : 0 }`;

const Item: React.FC<
InquiriesConfigurationFormServicesFieldProps & {
	provided?: DraggableProvided;
}
> = ( { provided, ...props } ) => {
	const [ serviceNames, setServiceNames ] = useState( {
		[ getTimestamp( 0 ) ]: '',
		[ getTimestamp( 1 ) ]: '',
	} );

	const reset = useCallback( () => {
		if ( props.services && Array.isArray( props.services ) ) {
			const services: string[] = props.services;
			setServiceNames(
				services.reduce(
					( acc, name, index ) => ( { ...acc, [ getTimestamp( index ) ]: name } ),
					{}
				)
			);
		} else {
			setServiceNames( { [ getTimestamp( 0 ) ]: '', [ getTimestamp( 1 ) ]: '' } );
		}
	}, [ props.services ] );

	const handleDelete: NonNullable<ServiceNameFieldProps['onDelete']> =
		useCallback(
			( id ) =>
				setServiceNames( ( prevState ) => {
					const { [ id ]: removed, ...newState } = prevState;
					return newState;
				} ),
			[]
		);

	const handleAdd = useCallback(
		() =>
			setServiceNames( ( prevState ) => ( {
				...prevState,
				[ getTimestamp() ]: '',
			} ) ),
		[]
	);

	const names = useMemo(
		() => ( props.services ? ( props.services as string[] ).filter( notEmpty ) : [] ),
		[ props.services ]
	);

	const {
		containerProps,
		DragHandle,
		classNames,
		label,
		setLabel,
		required,
		setRequired,
		modalProps,
		setServices,
		multiple,
		setMultiple,
	} = useInquiriesFormField( {
		...props,
		onUpdate: ( { label, services, multiple, required } ) => {
			props.setLabel( label );
			props.setServices( services );
			props.setRequired( required );
			props.setMultiple( !!multiple );
		},
		onReset: reset,
	} );

	const handleChange: ServiceNameFieldProps['onChange'] = useCallback(
		( id, value ) => {
			setServiceNames( ( prevState ) => ( { ...prevState, [ id ]: value } ) );
		},
		[]
	);

	useEffect( () => {
		setServices( Object.values( serviceNames ) );
	}, [ serviceNames, setServices ] );

	// Use the text field "services" as a JSON.stringified form of names as string[]
	useEffect( () => {
		reset();
	}, [ reset ] );

	return (
		<ListItem
			{ ...provided?.draggableProps }
			{ ...containerProps }
			ref={ provided?.innerRef }
		>
			{ provided ? <DragHandle { ...provided.dragHandleProps } /> : null }
			<ListItemText
				className={ classNames.text }
				primary={ label }
				secondary={
					<>
						{ props.validation ? (
							<Typography color='error'>{ props.validation }</Typography>
						) : null }
						{ names.length ? (
							<>
								{ names.map( ( name ) => (
									<Typography
										key={ name }
										component='span'
										className={ styles.names }
									>
										{ name }
									</Typography>
								) ) }
							</>
						) : null }
					</>
				}
			/>
			<MuiModal { ...modalProps }>
				<Box className={ classNames.modal }>
					<MuiTextFieldText
						fullWidth
						label='Label'
						value={ label }
						onChange={ ( e ) => setLabel( e.target.value ) }
						errorText={
							props.validation ===
							rpcShared.strings.errorMessages.inquiryFormRequiresLabels
								? props.validation
								: undefined
						}
						onBlur={ () => props.onBlur() }
					/>
					<List disablePadding className={ styles.list }>
						{ Object.entries( serviceNames ).map( ( [ id, name ], index ) => (
							<ServiceNameField
								key={ id }
								id={ id }
								value={ name }
								onChange={ handleChange }
								onDelete={ handleDelete }
								onBlur={ props.onBlur }
								errorText={
									props.validation ===
									rpcShared.strings.errorMessages
										.inquiryFormServicesCannotBeEmpty
										? props.validation
										: undefined
								}
							/>
						) ) }
					</List>
					<Box className={ classNamesHelper( classNames.buttons, styles.buttons ) }>
						<FormControlLabel
							className={ styles.checkbox }
							label='Required Field'
							disabled={ props.forceRequired }
							control={
								<MuiCheckbox
									checked={ props.forceRequired || required }
									onChange={ ( _, checked ) => setRequired( checked ) }
								/>
							}
						/>
						{ Object.entries( serviceNames ).length < 6 ? (
							<IconButton onClick={ handleAdd } data-cy='add-service-name'>
								<Icon type='plus' className={ classNames.addButton } />
							</IconButton>
						) : null }
						<FormControlLabel
							className={ styles.checkbox }
							label='Allow multiple selection'
							control={
								<MuiCheckbox
									checked={ !!multiple }
									onChange={ ( _, checked ) => setMultiple( checked ) }
								/>
							}
						/>
					</Box>
				</Box>
			</MuiModal>
		</ListItem>
	);
};

export const InquiriesConfigurationFormServicesField: React.FC<
InquiriesConfigurationFormServicesFieldProps
> = ( props ) => {
	/* we're using forceRequired as in indicator of non-draggable fields. Because I couldn't
	get @hello-pangea/dnd to let me mix single and double column items we have the narrow
	items either not draggable at all, or they drag as a single item */
	if ( props.forceRequired ) {
		return <Item { ...props } />;
	}

	return (
		<Draggable key={ props.type } draggableId={ props.type } index={ props.index }>
			{ ( provided ) => <Item { ...props } provided={ provided } /> }
		</Draggable>
	);
};
