import BalloonAnimationLoop from '../../public/BalloonAnimation_loop.gif';
import BalloonAnimation from '../../public/BalloonAnimation.gif';
import Image from 'next/image';
import styled from 'styled-components';

const AnimationBase = styled.div`
	pointer-events: none;
	position: absolute;
	width: 15rem;
	top: 7%;
	left: 42%;
	height: 21rem;

	img {
		/* letterbox the image to the container, clipping any that hangs
		ouside but maintaining aspect ratio */
		object-fit: cover;
	}

	@media (min-width: ${ ( { theme } ) => theme.mediumScreen }px) {
		width: 21.875rem;
		top: 15%;
		right: 15%;
		height: 30rem;
	}
`;

export default ( { looping, ...props } ) => (
	<AnimationBase { ...props }>
		<Image
			src={ looping ? BalloonAnimationLoop : BalloonAnimation }
			fill={ true }
			alt='Animation of a balloon floating up and popping'
		/>
	</AnimationBase>
);
