import { gql } from '@apollo/client';
import { useCallback, useEffect } from 'react';
import getBackendUrl, {
	EndpointNames,
} from '../../../lib/API/request/serviceMap';
import { showError, showSuccess } from '../../Toast';
import { useInquiriesConfiguration } from '../useInquiriesConfiguration';
import { getLoadInquiryFormJavaScript } from '../helpers';
import { isDevelopment } from '../../../lib/constants';
import { stringToClipboard } from '../../QuickPaymentsModal/helpers';
import { FlattenedUser } from '../../../types/user';
import { useSelector } from 'react-redux';
import { UserType } from '../../../types/generated';
import { useInquiriesPublish_GetInquiryFormApiKeyQuery } from './__generated__/useInquiriesPublish';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const getInquiryFormApiKeyQuery = gql`
	query InquiriesPublish_GetInquiryFormApiKey {
		getInquiryFormApiKey
	}
`;

export const useInquiriesPublish = ( {
	onSave,
	loading: queryOrMutationLoading,
}: Pick<
ReturnType<typeof useInquiriesConfiguration>,
'onSave' | 'loading'
> ) => {
	const user: FlattenedUser = useSelector( ( state: any ) => state.user );
	const organizationId =
		user?.isLoggedIn && user.userType === UserType.OrgUser
			? user.organization.id
			: undefined;

	const {
		data: apiKeyData,
		error: apiKeyError,
		loading: apiKeyLoading,
	} = useInquiriesPublish_GetInquiryFormApiKeyQuery();

	useEffect( () => {
		if ( apiKeyError ) {
			showError( apiKeyError );
		}
	}, [ apiKeyError ] );

	const apiKey = apiKeyData?.getInquiryFormApiKey;
	const apiURL = getBackendUrl( EndpointNames.Query );

	const handleSave = useCallback(
		async ( successMessage = 'Form saved' ) => {
			if ( apiKey ) {
				const success = await onSave( apiKey );
				if ( success ) {
					showSuccess( successMessage );
				}
				return success;
			} else {
				return false;
			}
		},
		[ apiKey, onSave ]
	);

	const code = `<div id="inquiry-form" ${
		isDevelopment ? `data-api="${ apiURL }"` : ''
	} data-api-key="${ apiKey }" data-organization-id="${ organizationId }"></div>
<script>${ getLoadInquiryFormJavaScript() }</script>`;

	const copyToClipboard = async () => {
		const success = await handleSave( 'Form saved and code copied to clipboard' );
		if ( success ) {
			await stringToClipboard( () => Promise.resolve( code ) );
		}
	};

	return {
		loading: queryOrMutationLoading || apiKeyLoading,
		code,
		onSave: handleSave,
		copyToClipboard,
	};
};
