import { gql } from '@apollo/client';
import { useCallback, useState, useEffect } from 'react';
import {
	InquiryFormField,
	InquiryFormFieldType,
	InquiryFormStyle,
	UserType,
} from '../../types/generated';
import {
	useInquiriesConfiguration_GetInquiryFormQuery,
	useInquiriesConfiguration_CreateInquiryFormMutation,
	useInquiriesConfiguration_UpdateInquiryFormMutation,
} from './__generated__/useInquiriesConfiguration';
import { useSelector } from 'react-redux';
import { FlattenedUser } from '../../types/user';
import { showError } from '../Toast';

export const inquiriesConfigurationInquiryFormFragment = gql`
	fragment inquiriesConfigurationInquiryForm on InquiryForm {
		apiKey
		instructions
		fields {
			order
			type
			label
			required
			services
			multiple
		}
		styles {
			type
			value
		}
	}
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const getInquiryFormQuery = gql`
	query InquiriesConfiguration_GetInquiryForm(
		$where: OrganizationWhereUniqueInput!
	) {
		getInquiryForm(where: $where) {
			...inquiriesConfigurationInquiryForm
		}
	}
	${ inquiriesConfigurationInquiryFormFragment }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const createInquiryFormMutation = gql`
	mutation InquiriesConfiguration_CreateInquiryForm(
		$data: InquiryFormDataInput!
	) {
		createInquiryForm(data: $data) {
			...inquiriesConfigurationInquiryForm
		}
	}
	${ inquiriesConfigurationInquiryFormFragment }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const updateInquiryFormMutation = gql`
	mutation InquiriesConfiguration_UpdateInquiryForm(
		$data: InquiryFormDataInput!
	) {
		updateInquiryForm(data: $data) {
			...inquiriesConfigurationInquiryForm
		}
	}
	${ inquiriesConfigurationInquiryFormFragment }
`;

export type ServicesField = {
	type: InquiryFormFieldType.Services;
} & Pick<
InquiryFormField,
'order' | 'label' | 'required' | 'services' | 'multiple'
>;
export type NotServicesField = {
	type: Exclude<InquiryFormFieldType, 'services'>;
} & Pick<InquiryFormField, 'order' | 'label' | 'required'>;
export type Field = ServicesField | NotServicesField;

type Style = Pick<InquiryFormStyle, 'type' | 'value'>;

export const useInquiriesConfiguration = () => {
	const user: FlattenedUser = useSelector( ( state: any ) => state.user );
	const [ instructions, setInstructions ] = useState<string>();
	const [ fields, setFields ] = useState<Field[]>();
	const [ styles, setStyles ] = useState<Style[]>();

	const [ createInquiryForm, { loading: createInquiryFormLoading } ] =
		useInquiriesConfiguration_CreateInquiryFormMutation( {
			refetchQueries: [ 'InquiriesConfiguration_GetInquiryForm' ],
		} );
	const [ updateInquiryForm, { loading: updateInquiryFormLoading } ] =
		useInquiriesConfiguration_UpdateInquiryFormMutation( {
			refetchQueries: [ 'InquiriesConfiguration_GetInquiryForm' ],
		} );

	const organizationId =
		user?.isLoggedIn && user.userType === UserType.OrgUser
			? user.organization.id
			: undefined;

	const {
		data: inquiryFormData,
		error,
		loading: queryLoading,
	} = useInquiriesConfiguration_GetInquiryFormQuery( {
		variables: {
			where: { id: organizationId || '' },
		},
		skip: !organizationId,
	} );

	useEffect( () => {
		if ( error ) {
			showError( error );
		}
	}, [ error ] );

	const loading =
		queryLoading || createInquiryFormLoading || updateInquiryFormLoading;

	const inquiryForm = inquiryFormData?.getInquiryForm;
	useEffect( () => {
		if ( inquiryForm ) {
			setInstructions( inquiryForm.instructions || undefined );
			setFields(
				inquiryForm.fields?.map( ( field ) => {
					const { order, label, type, required, services, multiple } = field;
					return { order, label, type, required, services, multiple };
				} ) || undefined
			);
			setStyles(
				inquiryForm.styles?.map( ( style ) => ( {
					type: style.type,
					value: style.value,
				} ) ) || undefined
			);
		}
	}, [ inquiryForm ] );

	const onSave = useCallback(
		async ( apiKey: string ) => {
			const response = await ( inquiryForm
				? updateInquiryForm
				: createInquiryForm )( {
				variables: {
					data: {
						apiKey,
						instructions,
						fields,
						styles,
					},
				},
			} );
			if ( response.errors ) {
				response.errors.forEach( ( error ) => showError( error ) );
				return false;
			} else {
				return true;
			}
		},
		[
			inquiryForm,
			instructions,
			fields,
			styles,
			createInquiryForm,
			updateInquiryForm,
		]
	);

	return {
		instructions,
		setInstructions,
		fields,
		setFields,
		styles,
		setStyles,
		loading,
		onSave,
	};
};
