import React, { useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import MuiButton from '../../../mui/MuiButton';
import styles from './InquiriesPublishForm.module.scss';
import { gql } from '@apollo/client';
import { useInquiriesPublishForm_GetGlobalVariablesQuery } from './__generated__';
import { showError } from '../../Toast';
import MuiSpinner from '../../../mui/MuiSpinner';
import ExtendedLink from '../../ExtendedLink/ExtendedLink';
import { useInquiriesPublish } from './useInquiriesPublish';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const getGlobalVariablesQuery = gql`
	query InquiriesPublishForm_getGlobalVariables {
		getGlobalVariables {
			InquiryFormSupportUrl
		}
	}
`;

export type InquiriesPublishFormProps = Pick<
ReturnType<typeof useInquiriesPublish>,
'onSave' | 'loading' | 'code' | 'copyToClipboard'
>;

export const InquiriesPublishForm: React.FC<InquiriesPublishFormProps> = ( {
	onSave,
	loading,
	code,
	copyToClipboard,
} ) => {
	const {
		data: urlData,
		error: urlError,
		loading: urlLoading,
	} = useInquiriesPublishForm_GetGlobalVariablesQuery();

	useEffect( () => {
		if ( urlError ) {
			showError( urlError );
		}
	}, [ urlError ] );

	return (
		<Box className={ styles.container }>
			{ loading ? (
				<Box className={ styles.loading }>
					<MuiSpinner />
				</Box>
			) : null }
			<Typography variant='h6'>Publish inquiry form</Typography>
			<Typography>Your inquiry form is ready to go.</Typography>
			<Typography>
				Copy the code and embed it in your website. You can find more detailed
				information and instructions{ ' ' }
				<ExtendedLink
					className='legacyLink'
					// the loading state will disable in the case of href being nothing
					href={ urlData?.getGlobalVariables.InquiryFormSupportUrl || '' }
					target='_blank'
					rel='noreferrer'
					loading={ urlLoading }
				>
					here
				</ExtendedLink>
				.
			</Typography>
			<Typography>
				Once the RPC form is live on your website all future updates will
				automatically populate on save.
			</Typography>
			<Box className={ styles.code }>
				{ code.split( '\n' )[ 0 ] }
				<br />
				{ code.split( '\n' )[ 1 ] }
			</Box>
			<Box className={ styles.buttons }>
				<MuiButton onClick={ () => onSave() } loading={ loading }>
					Save
				</MuiButton>
				<MuiButton onClick={ copyToClipboard } loading={ loading }>
					copy code
				</MuiButton>
			</Box>
		</Box>
	);
};
