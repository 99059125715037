import { Box, Typography } from '@mui/material';
import { useRouter } from 'next/router';
import React, { useCallback } from 'react';
import { useDeviceDetection } from '../../hooks/useDeviceDetection';
import MuiPaperBackButton from '../../mui/MuiPaper/MuiPaperBackButton';
import MuiSpinner from '../../mui/MuiSpinner';
import MuiStepper from '../../mui/MuiStepper/MuiStepper';
import { PageHead, PageHeadBaseProps } from '../helpers';
import styles from './InquiriesConfiguration.module.scss';
import { InquiriesConfigurationForm } from './InquiriesConfigurationForm';
import { useInquiriesConfigurationFields } from './InquiriesConfigurationForm/useInquiriesConfigurationFields';
import { InquiriesPublishForm } from './InquiriesPublishForm';
import { useInquiriesPublish } from './InquiriesPublishForm/useInquiriesPublish';
import { InquiriesStylingForm } from './InquiriesStylingForm';
import { useInquiriesConfiguration } from './useInquiriesConfiguration';

const InquiriesConfigurationSteps = {
	Setup: 'setup',
	Style: 'style',
	Publish: 'publish',
} as const;

type InquiriesConfigurationStepsType = typeof InquiriesConfigurationSteps;
type InquiriesConfigurationStepsValue =
	InquiriesConfigurationStepsType[keyof InquiriesConfigurationStepsType];

const stepLabels = {
	[ InquiriesConfigurationSteps.Setup ]: 'Setup',
	[ InquiriesConfigurationSteps.Style ]: 'Style',
	[ InquiriesConfigurationSteps.Publish ]: 'Publish',
};

export const queryValueIsInquiriesConfigurationStep = (
	potentialStep:
	| string
	| string[]
	| undefined
	| typeof InquiriesConfigurationSteps
): potentialStep is InquiriesConfigurationStepsValue =>
	typeof potentialStep === 'string' &&
	Object.values<string>( InquiriesConfigurationSteps ).includes( potentialStep );

export const Title = {
	[ InquiriesConfigurationSteps.Setup ]: 'Inquiries Form Setup',
	[ InquiriesConfigurationSteps.Style ]: 'Inquiries Form Styling',
	[ InquiriesConfigurationSteps.Publish ]: 'Inquiries Form Publish Markup',
};

const getNextStep = ( step: InquiriesConfigurationStepsValue ) => {
	switch ( step ) {
		case InquiriesConfigurationSteps.Setup:
			return InquiriesConfigurationSteps.Style;
		case InquiriesConfigurationSteps.Style:
			return InquiriesConfigurationSteps.Publish;
	}
};

const getPreviousStep = ( step: InquiriesConfigurationStepsValue ) => {
	switch ( step ) {
		case InquiriesConfigurationSteps.Style:
			return InquiriesConfigurationSteps.Setup;
		case InquiriesConfigurationSteps.Publish:
			return InquiriesConfigurationSteps.Style;
	}
};

const getPath = ( step?: InquiriesConfigurationStepsValue ) =>
	`/admin/inquiries/${ step }`;

export type InquiriesConfigurationProps = PageHeadBaseProps & {
	goggleFontsApiKey: string;
	step: InquiriesConfigurationStepsValue;
};

export const InquiriesConfiguration: React.FC<InquiriesConfigurationProps> = ( {
	goggleFontsApiKey,
	step,
	hostnameAndProtocol,
} ) => {
	const router = useRouter();
	const { isMobile } = useDeviceDetection();
	// cook down some values we'll use with the stepper
	const activeStep = Object.values(
		InquiriesConfigurationSteps as Record<string, string>
	).indexOf( step );

	const backButtonHref = '/admin?tab=settings';

	// So we can make the buttons spinners when one is clicked
	const {
		instructions,
		setInstructions,
		fields,
		setFields,
		styles: formStyles,
		setStyles,
		loading,
		onSave,
	} = useInquiriesConfiguration();

	const { onNext, ...hookProps } = useInquiriesConfigurationFields( {
		instructions,
		setInstructions,
		fields,
		setFields,
	} );

	const publishProps = useInquiriesPublish( { onSave, loading } );

	const handleSetActiveStep = useCallback(
		async ( newStep?: InquiriesConfigurationStepsValue ) => {
			if ( newStep ) {
				const newPath = getPath( newStep );
				if ( step === InquiriesConfigurationSteps.Publish ) {
					await router.push( newPath );
				} else if ( step === InquiriesConfigurationSteps.Setup ) {
					if ( onNext() ) {
						await router.push( newPath );
					}
				} else {
					await router.push( newPath );
				}
			}
		},
		[
			step,
			router,
			onNext
		]
	);

	const handleDone = useCallback( async () => {
		if ( await publishProps.onSave() ) {
			await router.push( backButtonHref );
		}
	}, [ publishProps, router ] );

	const handleOnNext = useCallback(
		() =>
			step === InquiriesConfigurationSteps.Publish
				? handleDone()
				: handleSetActiveStep( getNextStep( step ) ),
		[
			handleDone,
			handleSetActiveStep,
			step
		]
	);

	const handleOnBack = useCallback(
		async () => handleSetActiveStep( getPreviousStep( step ) ),
		[ handleSetActiveStep, step ]
	);

	return (
		<>
			<PageHead
				hostnameAndProtocol={ hostnameAndProtocol }
				title={ Title[ step ] }
				path={ `/admin/inquiries/${ step }` }
			/>
			<main className={ styles.main }>
				<MuiPaperBackButton href={ backButtonHref } text='Admin' />
				{ isMobile ? (
					<Typography className={ styles.desktopOnly }>
						Your inquiry form can only be configured on desktop.
					</Typography>
				) : (
					<MuiStepper
						className={ styles.stepper }
						stepChildrenClassName={ styles.stepperContent }
						steps={ InquiriesConfigurationSteps }
						labels={ stepLabels }
						activeStep={ activeStep }
						setActiveStep={ handleSetActiveStep }
						onNext={ handleOnNext }
						onBack={ handleOnBack }
						loading={ loading }
					>
						{ loading ? (
							<Box className={ styles.loading }>
								<MuiSpinner />
							</Box>
						) : null }
						{ step === InquiriesConfigurationSteps.Setup ? (
							<InquiriesConfigurationForm { ...hookProps } />
						) : null }
						{ step === InquiriesConfigurationSteps.Style ? (
							<InquiriesStylingForm
								googleFontsApiKey={ goggleFontsApiKey }
								loading={ loading }
								instructions={ instructions }
								fields={ fields }
								styles={ formStyles }
								setStyles={ setStyles }
							/>
						) : null }
						{ step === InquiriesConfigurationSteps.Publish ? (
							<InquiriesPublishForm { ...publishProps } />
						) : null }
					</MuiStepper>
				) }
			</main>
		</>
	);
};
